import React from "react";
import { NavLink } from "react-router-dom";

export const Sidebar = () => {
    return (
        <aside className="main-sidebar">
            <section className="sidebar">
                <ul className="sidebar-menu" data-widget="tree">
                    <li className="header">Навигация</li>
                    <li><NavLink to='/'><span>Главная</span></NavLink></li>
                    
                </ul>
            </section>
        </aside>
    )
}