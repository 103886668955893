import React from 'react';
import ReactDOM from 'react-dom';
import {App} from "./components/app/app";
import {BrowserRouter} from 'react-router-dom'
import "./styles/main.scss";

import {Provider} from 'react-redux'
import store from './redux/configureStore'


const app = (
    <Provider store={store}>
        <BrowserRouter>
            <App/>
        </BrowserRouter>
    </Provider>
)

ReactDOM.render(app, document.getElementById('root'));