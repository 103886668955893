import React, {Fragment} from "react";
import axios from "axios";
import {Sidebar} from "../sidebar/sidebar";
import Form from "../form/form";

export const App = () => {
    return (
        <Fragment>
            <Sidebar/>

            <Form/>
        </Fragment>
    )
}