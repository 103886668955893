import React, {Component} from "react";

export default class Form extends Component {
    state = {

    }

    render() {
        return (
            <div className="content-wrapper">
                <section className="content container-fluid">
                    <section className="content">
                        <div className="box">
                            <div className="box-body">
                                <div className="box-header">
                                    <h2 className="box-title">Создать "одностраничник"</h2>
                                </div>
                                <div className="box-body">
                                    <div className="col-md-6 col-xs-10 col-12">
                                        <form>
                                            <div className="form-group">
                                                <label htmlFor="document">Наименование документа</label>
                                                <input type="text" className="form-control" id="document" name="document"/>

                                                <div className="row mt-3">
                                                    <div className="col-4">
                                                        <label htmlFor="type">Типдокумента</label>
                                                        <select name="type" id="type" className="form-control">
                                                            <option value="0" selected disabled>--- Тип документа ---</option>
                                                        </select>
                                                    </div>
                                                    <div className="col-8">
                                                        <label htmlFor="description">Должностная инструкция</label>
                                                        <input type="text" className="form-control" id="description" name="description"/>
                                                    </div>
                                                </div>

                                                <label htmlFor="content">Оглаление</label>
                                                <textarea name="content" id="content" cols="10" rows="10" className="form-control"/>
                                            </div>

                                            <div className="form-group">
                                                <button className="btn btn-info" type="submit">Создать</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </section>
            </div>
        )
    }
}